import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import { Container, Row, Col, Tab, Nav, Form, Button, Breadcrumb, Accordion, Card, Carousel } from 'react-bootstrap';
import {Link} from "react-router-dom";
import './css/home.css';
import { FlashMess, GET_API, POST_API,Loader } from "../Helpers/Helpers";

import logo10 from "../images/GlobalWorkInitiative2.png"; // GBW


class home extends Component {
  componentDidMount(){
    let code = this.props.match.params.code
   // let refercode = this.props.match.params.refercode
    //console.log(code)
    // localStorage.setItem('affiliate_code',code)
     //localStorage.setItem('refer_code',refercode)

     
    GET_API({end_point:'/get-homepage-banner-details'}).then(resp=>{
      if(resp.status == 'success'){
        //console.log(resp);

        this.setState({BannerContent: resp.data.content})
        this.setState({BannerBtnText: resp.data.btn_text})

        //console.log('statevalue', this.state.BannerContent);
      }
    });    

   }
  componentWillMount(){

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let importcode = params.get('code');
    let importUrl = params.get('url');
    let token = params.get('token');
    let email = params.get('email');
    
    let userDetails = JSON.parse(localStorage.getItem("gs_user"))
  
    if(importcode != undefined && importUrl=='contactimport')
    {
      
      localStorage.setItem('import_code',importcode)
     
      // if(userDetails.roleID!=undefined && userDetails.roleID!=8)
      // {
      //   this.props.history.push({
      //     pathname: '/contact-details-sponsor/'+localStorage.getItem('group_id'),
      //   })
      // }else{
          this.props.history.push({
            pathname: '/contact-details/'+localStorage.getItem('group_id'),
          })
      //}
      //window.location = BASE_URL+'#/contact-details/'+localStorage.getItem('group_id');
      
    }
    //console.log('ttttttttttttttttttttttttt',token,email)

    if(token != undefined && email!=undefined)
    {
     
      localStorage.setItem('token',token)
      localStorage.setItem('email',email)
     
      if(userDetails.roleID!=undefined )
      {
        this.props.history.push({
            pathname: '/add-email-setting/',
          })
         
       
        // this.props.history.push({
        //   pathname: '/add-campaign-email-setting/',
        // })
        
      }else{
        console.log("check else",userDetails.roleID)
          // this.props.history.push({
          //   pathname: '/add-email-setting/',
          // })
         
      }
    
      
    }


   }
  render() {

    return (
      <div className="bannertop">
        <img className='pl-4 pt-4 imgSize' src={logo10} />
        <Container className="banner_container_home justify-content-between">
          <Row >
            <Col md={{span:5, offset: 1}} className="pr-4 left_content">
            <p className='job_l'>You can change everything Become a Sponsor now</p>
            <Button className="btn_header ml-1 sp_btn_fix_l" href="#/becomesponsor">Become Sponsor</Button>
            </Col>

            <Col md={5} className="pl-4">
            <p className='job_r'>Helping people Get Back To Work ONE-STOP-SHOP CAREER SERVICES</p>
            <Button className="btn_header ml-1 " href="#/becomejobseeker">JobSeeker Signup</Button>
            </Col>
          </Row>

        </Container>
      </div>
    )
  }
}










export default home;